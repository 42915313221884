import store from "@/store"
import { difference, get } from "lodash";
import { isValid, format } from "date-fns";
import { getPasswordErrorMessage } from "../utils";

export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const settings = store.getters['auth/settings']
  const password_settings = settings.password_complexity_settings

  if (!password_settings.enable_password_complexity_check) {
    return true;
  }

  const minimumLength = get(password_settings, "minimum_length", 8)
  const numberRegex = get(password_settings, "include_number", true) ? "(?=.*[0-9])" : "";
  const specialCharacterRegex = get(password_settings, "include_special_character", true) ? "(?=.*[^A-Za-z0-9])" : ""
  const upperAndLowercaseRegex = get(password_settings, "include_upper_and_lower_cased_characters", true) ? "(?=.*[A-Z])(?=.*[a-z])" : "";

  const message = getPasswordErrorMessage(password_settings);

  const regexString = `(?=^.{${minimumLength},}$)${numberRegex}${upperAndLowercaseRegex}${specialCharacterRegex}.*`

  const regExp = new RegExp(regexString);
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword || message;
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const termsValidator = val => {
  if (val === true) {
    return true
  }
  return false;
}

export const arrayValidator = (val) => {
  if (Array.isArray(val) && val.length > 0) {
    return true
  }
  return false
}

export const validateDate = (val) => {
  try {
    return isValid(new Date(format(new Date(val), "yyyy-MM-dd")));
  } catch (_) {
    return false
  }
}


export function isValidPolicyStatement(statement) {
  const expectedKeys = ["actions", "resource", "effect"];
  const keys = Object.keys(statement);
  if (difference(keys, expectedKeys).length !== 0) {
    throw new Error("Not equal statement keys")
    // return false;
  }
  const allowedEffects = ["Allow", "Deny"]
  if (!allowedEffects.includes(statement.effect)) throw new Error(`Invalid effect ${statement.effect}`);
  if (typeof (statement.resource) !== "string") throw new Error("Invalid resource specified");

  if (statement.actions.every((action) => typeof (action) === "string" && action.trim.length > 0)) {
    throw new Error("Invalid actions provided");
  }
}

export function validatePolicy(payload) {
  if (!payload.name) throw new Error("Name is required");
  if (!payload.name.trim().length > 3) throw new Error("Name must be at least 3 characters")
  if ((!Array.isArray(payload.statements))) throw new Error("Statements not provided");
  if (payload.statements.length === 0) throw new Error("Statements must have at least one item")
  payload.statements.forEach(statement => isValidPolicyStatement(statement));
}
